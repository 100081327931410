<template>
<div class="intro-container">
    <b-container class="tcontainer mt-5" fluid="sm">
        <b-row class="row"> 
            <b-col class="intro mr-0 pr-0">Hi. My name is</b-col>
            <b-col class="mt-5" sm="4">
                <b-col class="text name">Russell Pacheco.</b-col>
                <b-col class="text position">I am a<br>software engineer.</b-col>
            </b-col>
            <b-col class="pl-0" cols="4">
                <img id="image1" src="../assets/img/personal_photo.png" />
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
export default {
    name: "Introduction"

}
</script>

<style scoped>

.intro-container {
    display: flex;
    margin-top: 2%;
    width: 58%;
}

.intro {
    color: white;
    font-size: 110%;
    margin-right: 7%;
    text-align: left;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 3%;
}

.text {
    font-size: 250%;
    text-align: left;
    line-height: 130%;
    margin-left: -70%;
}

.name {
    color: #CA6131;
}

.text.position {
    color: white;
}

.img-container {
    margin-top: 3%;
    margin-left: 3%;
    width: 25vw;
}

img {
    opacity: .5;
    margin-top: 10%;
    margin-left: -100%;
}

img:hover {
    opacity: 1.0;
}

@media (max-width: 750px) {
    img {
        display: none;
    }

    .intro {
        margin-bottom: -20%;
        margin-left: -15%;
    }

    .text.position {
        margin-left: -10%;
    }

    .name {
        margin-left: -10%;
    }
    .tcontainer {
        margin-left: -15%;
    }

}

@media screen and (min-width: 751px) and (max-width: 850px) {

    .tcontainer {
        margin-left: -20%;
        width: 100%;
        padding-bottom: 5%;
    }

    .text {
        width: 100vw;
        margin-left: -65%;
    }
    
    #image1 {
        margin-top: 60%;
        margin-left: 40%;
    }
}


@media screen and (min-width: 700px) and (max-width: 900px) {

    .intro {
        margin-left: -10%;
    }

    .text {
        margin-left: -70%;
    }
    
    img {
    opacity: .9;
    margin-left: -60%;
    }
    
}

@media screen and (min-width: 901px) and (max-width: 1200px) {


    .text {
        margin-left: -60%;
    }   
    
    img {
        margin-left: -150%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .row {
        margin-left: -20%;
    }

    .text {
        margin-left: -50%;
    }

    #image1 {
        margin-left: -20%;
    }

    


}
</style>
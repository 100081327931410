<template>
  <div class="txt-output">
      <div v-for="(line, index) in textOutput" :key="index">
        <div v-html="line"></div>
      </div> 
  </div>
</template>
    
<script>
export default {
    name: "Screen",
    props: {
        textOutput: {
            type: Array
        }
    }
  
}
</script>



<style>

.txt-output {
    width: 500px;
    height: 400px;
    margin: 50px auto 0;
    background-color: black;
    color: #61864B;
    border-style: groove;
    border-color: grey;
    border-radius: 4px;
    text-align: center;
}

</style>
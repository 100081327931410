<template>
    <div>
    <b-card no-body class="b-card mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button class="button" block v-b-toggle="accID"><img class="folder-img" src="../assets/img/folder.png" />{{ title }}</b-button>
      </b-card-header>
      <b-collapse :id="accID" accordion="my-accordion" role="tabpanel">
        <b-card-body class="b-card-body">
          <b-card-text style="text-align: left;"><div v-html="summary"></div></b-card-text>
              <b-container class="icon-container">
                <b-row >
                  <b-col v-for="(linkObj, index) in buttonLinks" :key="index">
                    <div v-if="linkObj.type == 'web'">
                      <a :href="linkObj.link"><img src="../assets/img/domain.png" class="icon domain" /></a>
                    </div>
                    <div v-else-if="linkObj.type == 'modal'">
                      <b-button v-b-modal="linkObj.link" class="modal-button">Play</b-button>
                    </div>
                    <div v-else-if="linkObj.type == 'github'">
                      <a :href="linkObj.link"><img src="../assets/img/github.png" class="icon github"/></a>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
    </div>
</template>

<script>
 export default {
    name: 'AccordionSection',
    props: {
        title: String,
        summary: String,
        buttonLinks: Array,
        accID: String
    },
 }
</script>

<style scoped>

.b-card {
  border-style: none;
  background-color: #313335
}

.b-card-body {
    background-color: #3A3D3F;
    text-align: left;
    margin-top: 1%;
}

.button {
    text-align: left;
    background-color: #3A3D3F;
    border-style: none;
}
.header {
  background-color: #313335;
}

.folder-img {
    padding-right: 2%;
    width: 7%;
    filter: invert(50%)
}

.icon {
  filter: invert(100%);
  margin-top: 3%;
}

.icon.start {
  filter: invert(90%);
  margin-left: 60%;
  margin-top: -1%;
}

.icon.domain {
  width: 50px;
  margin-left: 60%;
}

.icon.github {
  width: 55px;
  height: 49px;
  margin-left: 20%;
}

.modal-button {
  margin-top: 7%;
  margin-left: 40%;
  background-color: black;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .icon.domain {
    margin-left: 40%;
  }
  
  .icon.start {
    margin-top: -3%;
    margin-left: 30%;
  }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
  .icon.domain {
    margin-left: 70%;
  }
  
}

</style>
<template>
<div>
    <b-navbar class="nav-bar" toggleable="lg" type="dark">
      <b-navbar-brand href="#">[RP]</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
            <b-nav-item href="#aboutme"><span class="navbar-item about-me">About Me</span></b-nav-item>
            <b-nav-item href="#abilities"><span class="navbar-item abilities">Abilities</span></b-nav-item>
            <b-nav-item href="#projects"><span class="navbar-item projects">Projects</span></b-nav-item>
            <b-nav-item href="#contact"><span class="navbar-item contact">Contact</span></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
  </b-navbar>
</div>

</template>

<script>
export default {
    name: "Navbar",
}
</script>

<style scoped>

.nav-bar {
    background-color: #313335;
}

.navbar-item.about-me {
    color: #CA6131
}

.navbar-item.abilities {
    color: #D8C557
}

.navbar-item.projects {
    color: #90538A
}

.navbar-item.contact {
    color: #7D81BF
}


@media screen and (min-width: 751px) and (max-width: 850) {
    #nav-collapse {
        display: initial;
        visibility: visible;
    }

}
</style>
<template>
<b-container class="abilities-container" id="abilities">
    <b-row class="title">
        <b-col class="title top">Abilities</b-col>
    </b-row>
    <b-row class="tech">
        <b-col class="title left">Abilities</b-col>
        <b-col class="tech title">
            <div id="programming-languages">Programming Languages:</div>
            <div id="libraries">Frameworks/Libraries:</div>
            <div id="misc">Misc Skills:</div>
        </b-col>
        <b-col class="tech details">
            <div id="tech-languages">Python, JavaScript, HTML, CSS, SQL</div>
            <div id="tech-libraries">Flask, Vue/Vuex, SQLAlchemy, Alembic, Node.js, Mocha, PyTest, Pacemaker, LangChain, etc.</div>
            <div id="tech-misc">PostgreSQL, Oracle, MariaDB, AWS, AJAX, Websockets, GIT, Linux, etc.</div>
        </b-col>
    </b-row>
</b-container>  
</template>

<script>
export default {
    name: "Abilities"
}
</script>

<style scoped>
.abilities-container {
    background-color: #313335;
    width: 58%;
    height: max-content;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.title {
    font-size: 33px;
    color: #D8C557;
}

.title.left {
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;    
}

.title.top {
    display: none;
}

.tech {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
}

.tech.details {
    text-align: left;
    margin-top: 1.1%;
    margin-left: -2%;
}

.tech.title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8.6%;
    margin-left: -30%;
    text-align: right;
}

#programming-languages {
    margin-top: -19%;
    padding-bottom: 2.5%;
}

#tech-languages {
    padding-bottom: 3%;
}

#misc {
    margin: -24px 0 0 0;
}

#tech-libraries {
    padding-bottom: 3%;
}

@media screen and (min-width: 300px) and (max-width: 374px) {

    .title.top {
        display: initial;
        padding-top: 5%;
        padding-bottom: 10%;
    }

    .title.left {
        display: none;
    }

    .abilities-container {
        width: 100%;
        padding-bottom: 10%;
    }

    .tech.title {
        margin-top: 8%;
        margin-left: 0%
    }

    .tech.details {
        margin-left: -5%;
        margin-top: 7.6%
    }

    #libraries {
        font-size: 12px;
        padding-bottom: 155%;
    }

    #programming-languages {
        padding-bottom: 34%;
    }
}

@media screen and (min-width: 375px) and (max-width: 392px) {
        .title.top {
        display: initial;
        padding-top: 5%;
        padding-bottom: 10%;
    }

    .title.left {
        display: none;
    }

    .abilities-container {
        width: 100%;
        padding-bottom: 10%;
    }

    .tech.title {
        margin-top: 8%;
        margin-left: 0%
    }

    .tech.details {
        margin-left: -5%;
        margin-top: 7.6%
    }

    #libraries {
        font-size: 12px;
        padding-bottom: 133%;
    }

    #programming-languages {
        padding-bottom: 34%;
    }    
}

@media screen and (min-width: 393px) and (max-width: 400px) {
    .abilities-container {
        width: 100%;
        padding-bottom: 10%;
    }
    
    .title.top {
        display: initial;
        padding-top: 5%;
        padding-bottom: 10%;
    }

    .title.left {
        display: none;
    }

    .tech.title {
        margin-top: 8%;
        margin-left: 0%
    }

    .tech.details {
        margin-left: -5%;
        margin-top: 7.6%
    }

    #libraries {
        font-size: 12px;
        padding-bottom: 112%;
    }

    #programming-languages {
        padding-bottom: 34%;
    }        
}

@media screen and (min-width: 401px) and (max-width: 500px) {
    .abilities-container {
        width: 100%
    }

    .title.top {
        display: initial;
        padding-bottom: 10%;
    }

    .title.left {
        display: none;
    }
    
    .tech {
        margin-left: 25%
    }

   .tech.details {
        margin-top: 7.5%;
    }
    
    #programming-languages {
        padding-bottom: 32%;
    }

    #libraries {
        font-size: 13px;
        padding-bottom: 131%
    }   
}

@media screen and (min-width: 751px) and (max-width: 900px) {
    .abilities-container {
        width: 100%;
    }

    .tech {
        padding-left: 5%;
        padding-bottom: 2%;
    }
    
    .title.left {
        display: none;
    }

    .title.top {
        display: initial;
        padding-bottom: 5%;
        padding-top: 2%;
    }

    #tech-languages {
        margin-top: -5.5%
    }

    #libraries {
        padding-bottom: 20.5%;
    }

}

@media screen and (min-width: 901px) {
    .abilities-container {
        width: 100%;
    }

    #tech-languages {
        margin-top: -0.5%;
    }

    #libraries {
        padding-bottom: 12.5%;
    }
}

</style>
<template>
  <div class="accordion" role="tablist">
    <div v-for="(section, index) in accordionData" :key="index">
      <AccordionSection 
        :title="section.title"
        :summary="section.summary"
        :buttonLinks="section.buttonLinks"
        :accID="'accordion-' + index.toString()"
        :key="index"
      />
    </div>
  <b-modal id="antgame" 
              centered 
              title="Ant Game" 
              size="lg"
              :header-bg-variant="'dark'"
              :header-text-variant="'light'"
              :body-bg-variant="'dark'"
              :body-text-variant="'dark'"
              :footer-bg-variant="'dark'" 
              :footer-text-variant="'dark'"
              >
                <Ant />
              </b-modal>

  </div>
</template>

<script>
import Ant from "./Ant.vue"
import AccordionSection from "./AccordionSection.vue"

export default {
    name: "Accordion",
    components: {
      Ant,
      AccordionSection
    },
    data() {
      return {
        accordionData: [
          {
            title: "MoneySprouts",
            summary: "Get in the black, by going green!<br><br>MoneySprouts is an environmentally conscientious web app that helps its users to save money and accomplish their personal environmental goals at the same time.<br><br>Built in JavaScript and Python.",
            buttonLinks: [
              {
                link: "https://github.com/RussellPacheco/moneysprouts",
                type: "github"
              }
            ]
          }, {
            title: "TJEE Website",
            summary: "Personalized website for the TJEE language exchange group for handling group admin related tasks with a web UI.",
            buttonLinks: [
              {
                link: "https://github.com/RussellPacheco/tjee_website",
                type: "github"
              }
            ]
          }, {
            title: "Ant v2",
            summary: "A text-based game where the player navigates a forest in order to survive as an ant. I am still working on the game, and updating it with newer content and features.",
            buttonLinks: [
              {
                link: "antgame",
                type: "modal"
              }, {
                link: "https://github.com/RussellPacheco/Ant_v2",
                type: "github"
              }
            ]
          }, {
            title: "Mercari Scraper",
            summary: "Webscraper for the Mercari website used for searching for items based on prices and other settings. Can be run on the command line.",
            buttonLinks: [
              {
                link: "https://github.com/RussellPacheco/mercari_scraper",
                type: "github"
              }
            ]
          }
        ]
      }
    }

}
</script>

<style scoped>

.accordion {
    color: white;
}

.b-card {
  border-style: none;
  background-color: #313335
}

.b-card-body {
    background-color: #3A3D3F;
    text-align: left;
    margin-top: 1%;
}

.button {
    text-align: left;
    background-color: #3A3D3F;
    border-style: none;
}
.header {
  background-color: #313335;
}

.folder-img {
    padding-right: 2%;
    width: 7%;
    filter: invert(50%)
}

.icon {
  filter: invert(100%);
  margin-top: 3%;
}

.icon.start {
  filter: invert(90%);
  margin-left: 60%;
  margin-top: -1%;
}

.icon.domain {
  width: 50px;
  margin-left: 60%;
}

.icon.github {
  width: 55px;
  height: 49px;
  margin-left: 20%;
}

.antgame-button {
  margin-top: 7%;
  margin-left: 40%;
  background-color: black;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .icon.domain {
    margin-left: 40%;
  }
  
  .icon.start {
    margin-top: -3%;
    margin-left: 30%;
  }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
  .icon.domain {
    margin-left: 70%;
  }
  
}

</style>
<template>
<b-container class="aboutme-container" id="aboutme">
    <b-row>
        <b-col>
            <div class="title top">About Me</div>
        </b-col>
    </b-row>
    <b-row class="contents">
        <b-col class="title left" cols="3">About Me</b-col>
        <b-col class="curlies" cols="2">
            <div class="orange curly left">{</div>
        </b-col>
        <b-col class="summary">I have been living in Japan since 
            2012 and had a number of different 
            jobs, before finding the 
            opportunity to pursue my interest 
            in technology and programming. 
            <br><br>
            A tech-geek since a young age, I 
            have dabbled in networking, 
            hardware, and later on in programming.
            <br><br>
            I am proficient in full-stack and web 
            development, and am open to new 
            opportunities that may require my
            expertise!</b-col>
        <b-col class="curlies" cols="2">
            <div class="orange curly right">}</div>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
export default {
    name: "AboutMe"
}
</script>

<style scoped>
.aboutme-container {
    display: flex;
    flex-direction: column;
    background-color: #313335;
    width: 58%;
    height: max-content;
    padding-top: 1%;
    padding-bottom: 1%;
}

.contents {
    display: flex;
}

.title, .curlies {
    color: #CA6131;
    font-size: 33px;
}

.title.left {
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}

.title.top {
    display: none;
}

.summary {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    text-align: left;
    line-height: 130%;
    font-size: 18px;
    margin-left: -2%;
}

.curlies {
    margin-top: auto;
    margin-bottom: auto;
    line-height: 40vh;
    margin-left: -5%;
    margin-right: 5%;
}

.orange.curly {
    font-size: 365px;
}


@media screen and (min-width: 300px) and (max-width: 750px) {
    .aboutme-container {
        display: flex;
        background-color: #313335;
        width: 100%;
        height: max-content;
        margin-top: 15%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .summary {
        margin-left: 5%;
    }

    .title.left {
        display: none;
    }

    .curlies {
        display: none;
    }
    
    .contents {
        padding-top: 8%;
    }

    .title.top {
        display: initial;
    }

}

@media screen and (min-width: 751px) and (max-width: 850px) {
    .aboutme-container {
        width: 100%;
        height: max-content;
        padding-bottom: 5%;
        padding-top: 3%;

    }

    .title.left {
        display: none;

    }

    .title.top {
        display: initial;
    }

    .curlies {
        display: none;
    }

    .summary {
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 851px) and (max-width: 990px) {
    .aboutme-container {
        width: 100%;
    }
    .summary {
        padding-left: 5%;
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .curlies {
        display: none;
    }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
    .aboutme-container {
        width: 100%;
    }

    .summary {
        padding-left: 5%;
        padding-top: 3%;
        padding-bottom: 3%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .aboutme-container {
        width: 100%;
        height: min-content;
    }

    .orange.curly {
        font-size: 325px;
    }

    .summary {
        margin-left: -5%;
        padding-right: 3%;
        padding-top: 2%;
        padding-bottom: 2%;
    }
}


</style>
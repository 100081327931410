<template>
<div>
  <Screen :textOutput="textOutput"/>
  <Commands :cmdOutput="cmdOutput"/>
  <div class="txt-input-container">
      <input id="txt-input" type="text" v-model="userInput" @keyup.enter="sendMessage()"/>
  </div>    
</div>
</template>

<script>
import Screen from "./Ant/Screen.vue"
import Commands from "./Ant/Commands.vue"

export default {
  name: 'Ant',
  components: {
    Screen,
    Commands,
  },

  data: function() {
    return {
      ws: null,
      textOutput: ["<br>", "Welcome to the Ant Game!", "<br>", "<br>", "<br>", "<br>",  "<br>",  "<br>", "Type start to continue"], // Screen outputs
      textOutputQueue: [],
      cmdOutput: ["Please type: start"], // Command output
      userInput: "",
      inSleep: false
    }
  },

  methods: {
    sendMessage() {
      this.ws.send(JSON.stringify({
        usercommand: this.userInput
      }))
      this.userInput = ""
      // console.log("sending a message")
    },

    enqueue(dataObj){
      this.textOutputQueue.push(dataObj)
    },

    sleep(delay) {
      return new Promise((resolve) => setTimeout(resolve, delay * 1000))
    },

    async dequeue() {
      while (this.textOutputQueue.length && !this.inSleep) {
        // console.log(`1 This is the obj: ${this.textOutputQueue} and this is inSleep ${this.inSleep}`)
        if (!this.inSleep) {
          const dequeued = {...this.textOutputQueue.shift()};
          // console.log("2 this is the object", {...dequeued})

          this.cmdOutput = [dequeued.cmdoutput]
          // console.log("3 This is dequeued sleep", dequeued.sleep)
          if (dequeued.clear) {
            this.clearScreen()
          }

          if (dequeued.sleep) {
            // console.log("inside dequeued sleep")
            this.inSleep = true
            // console.log("4 after dequeued with sleep number goes into loop, in sleep is now", this.inSleep)
            for (let i=0;i<[...dequeued.textoutput].length;i++) {
              // console.log("5 loop ", i + 1)
              this.textOutput.push([...dequeued.textoutput][i])
              await this.sleep(dequeued.sleep)
            }
            this.inSleep = false
            // console.log("6 Sleep has turned off and is now", this.inSleep)
          } else {
            for (let i=0;i<[...dequeued.textoutput].length;i++) {
              this.textOutput.push([...dequeued.textoutput][i])
            }
          }
        }
        this.dequeue()
      }      
    },

    clearScreen() {
      this.textOutput = [];
    },

    processServerMessage(data) {

      

      this.enqueue(data)
      if(!this.inSleep) {
        this.dequeue()
      }

      //this.textOutputQueue = [...this.textOutputQueue, ...data.textoutput]
      //this.textOutput = [...this.textOutput, ...data.textoutput]
    }

  },

  created() {
    const vue = this
    this.ws = new WebSocket(`wss://${process.env.VUE_APP_WSSERVER}`)
    
    this.ws.onopen = function() {
      // console.log("connected to the server")
    };
  
    this.ws.onmessage = function(event) {
      // console.log("got a message from the server")
      const receivedData = JSON.parse(event.data)

      // {
      //   clear: Boolean,
      //   cmdoutput: String,
      //   delayprint: Boolean,
      //   sleep: Boolean || Number,
      //   textoutput: Array
      // }


      // {
      //   usercommand: String
      // }
      vue.processServerMessage(receivedData)
    };
  }
}
</script>

<style scoped>

#txt-input-container {
    width: 600px;
    height: 80px;
    margin: 15px auto 0;
    
}

#txt-input {
    width: 600px;
    height: 40px;
    margin: 30px auto 0;
    background-color: black;
    border-style: groove;
    border-color: grey;
    border-radius: 4px;
    color:white;
}

</style>






// [{delay: false, text: [] }, {delay: 2, text ["ant is resting", "ant is resting"]]
// check whether delay exists or not
// if it does not exist add text to textOutput variable
// if delay does exist, loop through each element of its text property and push it with a set interval

// when we push something to textOutput from the queue, we delete it from the queue.



// if this was the queue: [1, 2, 3, 4, 30]         30\

<template>
<b-container class="contact-container" id="contact">
    <b-row>
        <b-col class="title top">Contact</b-col>
    </b-row>
    <b-row>
        <b-col class="title left">Contact</b-col>
        <b-col class="text">
            <b-row>
                <b-col>
                    <h1 class="text title">Well, what now?</h1>
                    <div class="text summary">How about getting in touch with me? I am looking for a new 
                        opportunity and so my inbox is always open. Feel free to email in 
                        case you have a question or just to say hi! I'll get back to you as
                        soon as possible.</div>
                </b-col>
            </b-row>
            <b-row class="icons">
                <b-col>
                    <a href="mailto:russ1487+pssite@gmail.com"><img src="../assets/img/email.png" class="icon email" /></a>
                </b-col>
                <b-col>
                    <a href="https://www.github.com/russellpacheco"><img src="../assets/img/github.png" class="icon github" /></a>
                </b-col>
                <b-col>
                    <a href="https://www.linkedin.com/in/russell-pacheco"><img src="../assets/img/linkedin.png" class="icon linkedin" /></a>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</b-container>
  
</template>

<script>
export default {
    name: "Contact"

}
</script>

<style scoped>
.contact-container {
    background-color: #313335;
    width: 58%;
    padding-top: 2%;
    padding-bottom: 2%
}

.title {
    color: #7D81BF;
    font-size: 33px;
}

.title.top {
    display: none;
}

.title.left {
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}

.text {
    color: white;
}

.text.summary {
    padding-top: 3%;
    color: #B3B4B5;
    font-size: 14px;
    text-align: left;
}

.icons {
    padding-top: 8%;
}

.icon {
    filter: invert(100%);
    width: 50px;
}

@media screen and (min-width: 300px) and (max-width: 400px) {
    .contact-container {
        width: 100%;
    }

    .title.left {
        display: none;
    }

    .title.top {
        display: initial;
        padding-top: 5%;
        padding-bottom: 10%;
    }

    .icons {
        padding-top: 15%;
        padding-bottom: 5%;
    }
    
}

@media screen and (min-width: 401px) and (max-width: 500px) {
    .contact-container {
        width: 100%;
    }
    
    .title.left {
        display: none;
    }

    .title.top {
        display: initial;
        padding-bottom: 7%;
    }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
    .contact-container {
        width: 100%;
    }

    .title.top {
        display: initial;
        padding-bottom: 5%;
    }

    .title.left {
        display: none;
    }

}

@media screen and (min-width: 901px) and (max-width: 1400px) {
    .contact-container {
        width: 100%;
    }
}

</style>
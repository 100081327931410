<template>
  <div>
    <div class="cmd-output">
        {{cmdOutput[0]}}
    </div>      
  </div>
</template>

<script>
export default {
    name: "Commands",
    props: {
        cmdOutput: {
            type: Array
        }
    }

}
</script>

<style>

.cmd-output {
    width: 600px;
    height: 80px;
    margin: 5px auto 0;
    background-color: black;
    color: white;
    border-style: groove;
    border-color: grey;
    border-radius: 4px;
    text-align: center;
}



</style>